import { createQuery } from "@tanstack/solid-query";
import { HTTPError } from "ky";

import { Transaction, fetchTransactions } from "#root/module/api";

import { TRANSACTIONS_KEY } from "./cacheKeys";
import { useHttp } from "./http";
import { config } from "#root/config";

export type ProductOverlayArgs = {
  userId: string;
  email: string;
};

export function generateProductOverlayUrl({
  userId,
  email,
}: ProductOverlayArgs) {
  const baseUrl = `https://asklive.lemonsqueezy.com/checkout/buy/${config.LS_PRODUCT}`;

  const url = new URL(baseUrl);
  url.searchParams.append("embed", "1");
  url.searchParams.append("checkout[custom][user_id]", userId);
  url.searchParams.append("checkout[email]", email);

  return url.toString();
}

export function useTransactions() {
  const { getClient } = useHttp();

  return createQuery<Transaction[], HTTPError>(() => {
    return {
      queryKey: [TRANSACTIONS_KEY],
      queryFn: () => fetchTransactions(getClient()).then(({ data }) => data),
    };
  });
}
