import { Show, Index } from "solid-js";

import { Box, VStack } from "#style/jsx";
import { css } from "#style/css";

import { AppDashboardHeader } from "#root/components/AppDashboardHeader";
import { AppPageContainer } from "#root/components/AppPageContainer";
import { useTransactions } from "#root/domain/payments";
import { useTranslation } from "#root/domain/i18n";
import { Typography } from "#root/components/Typography";
import { Transaction } from "#root/module/api";
import { formatDateFromISO } from "#root/module/date";

export default function Account() {
  const { t } = useTranslation();
  const transactions = useTransactions();

  return (
    <AppPageContainer>
      <AppDashboardHeader pageTitle={t("account.pageTitle")} />
      <Box mt="10">
        <Show when={transactions.data}>
          {(data) => <TransactionsSection transactions={data()} />}
        </Show>
      </Box>
    </AppPageContainer>
  );
}

type TransactionsSectionProps = {
  transactions: Transaction[];
};

function TransactionsSection(props: TransactionsSectionProps) {
  const { t } = useTranslation();
  const lineContainerStyle = css({
    "& tr": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: "10",
      py: "2",

      "& th, & td": {
        textAlign: "left",
      },
    },
  });

  return (
    <VStack
      w="full"
      gap="10"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Typography
        textStyle="smallImportant"
        class={css({ color: "lightGray" })}
      >
        {t("account.recentTransationsTitle")}
      </Typography>

      <table>
        <thead class={lineContainerStyle}>
          <tr>
            <Typography
              tag="th"
              textStyle="xsmallImportant"
              class={css({ color: "lightGray" })}
            >
              {t("account.productColumn")}
            </Typography>
            <Typography
              tag="th"
              textStyle="xsmallImportant"
              class={css({ color: "lightGray" })}
            >
              {t("account.productColumn")}
            </Typography>
            <Typography
              tag="th"
              textStyle="xsmallImportant"
              class={css({ color: "lightGray" })}
            >
              {t("account.priceColumn")}
            </Typography>
          </tr>
        </thead>

        <tbody class={lineContainerStyle}>
          <Index each={props.transactions}>
            {(transaction) => (
              <tr>
                <Typography tag="td" textStyle="xsmall">
                  x{transaction().quantity} AskLive Credit
                </Typography>
                <Typography tag="td" textStyle="xsmall">
                  {formatDateFromISO(transaction().insertedAt)}
                </Typography>
                <Typography tag="td" textStyle="xsmall">
                  {transaction().totalFormatted}
                </Typography>
              </tr>
            )}
          </Index>
        </tbody>
      </table>
    </VStack>
  );
}
